<template>
    <enso-table class="box is-paddingless raises-on-hover"
        id="alerts"/>
</template>

<script>
import { EnsoTable } from '@enso-ui/bulma';

export default {
    name: 'Index',

    components: { EnsoTable },
};
</script>

<style lang="scss">
</style>
